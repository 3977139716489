<template>
    <div class="container">
        <ul class="content-list">
            <li v-for="row in list" :key="row.id">
                <div @click="showDetailHandle(row)">
                    <img
                        :src="'http://zp.inlarks.com' + row.cover + '?x-oss-process=image/resize,m_fill,h_400,w_600'" />
                    <!-- <div>
                        <i class="icon-play"></i>
                    </div> -->
                </div>
                <h3>{{ row.title }}</h3>
                <p>{{ row.desc }}</p>
            </li>
        </ul>
        <div class="load-more" v-if="list.length > 0 && !ended">
            <div v-if="!loading" @click="loadData">
                <span>点击加载更多</span>
                <i class="icon-more-down"></i>
            </div>
            <div v-else>
                <i class="icon-loading"></i>
            </div>
        </div>
        <transition name="fade">
            <div v-if="showDetail" style=" position: fixed; left: 0; top: 0; right: 0; bottom: 0;z-index:1;">
                <img src="@/assets/close.png" class="close-btn" @click="showDetail = false;" v-if="!showPreviewStatus">
                <div class="preview-list">
                    <vue-waterfall-easy :imgsArr="temp.list" :maxCols="6" :enablePullDownEvent="false" :gap="10"
                        ref="waterfall" @click="showPreview" linkRange="custom" @preloaded="loadCompleted"
                        :imgWidth="350">
                        <!-- <template slot-scope="scope">
                            <img :data-idx="scope.index" @click="showPreview" :src="scope.value.src"
                                class="alink img-wraper" />
                        </template> -->
                    </vue-waterfall-easy>

                </div>
            </div>
        </transition>
        <transition name="fade">
            <!-- <VideoDetail :detail="temp" v-if="showDetail" @onClose="closeHandle"></VideoDetail> -->
            <VueEasyLightbox escDisabled loop :visible="showPreviewStatus" :index="preview_idx" :imgs="preview"
                @hide="closeHandle">
            </VueEasyLightbox>
        </transition>
    </div>
</template>

<script>
// import VideoDetail from "@/components/VideoDetail";
import columns from "@/column.config.js";
import VueEasyLightbox from 'vue-easy-lightbox';
import vueWaterfallEasy from 'vue-waterfall-easy'
export default {
    name: "ImgList",
    components: { VueEasyLightbox, vueWaterfallEasy },

    data() {
        return {
            columnId: columns[0].id,
            list: [],
            ended: false,
            temp: {},
            preview: [],
            loading: false,
            showDetail: false,
            page: 0,
            preview_idx: 0,
            showPreviewStatus: false
        };
    },
    watch: {
        $route(to) {
            this.columnId = to.params.column;
            this.OnColumnChange(to.params.column);
        },
    },
    mounted() {
        if (this.$route.params.column) this.columnId = this.$route.params.column;
        this.$showLoading();
        this.loadData();
        this.windowScroll();
        if (this.$route.query.id && this.$route.query.id > 0) {
            this.$http.get(`http://zp.api.inlarks.com/api/zp/img/detail/${this.$route.query.id}`).then((res) => {
                this.showDetailHandle(res.data);
            });
        }
    },
    methods: {
        loadData() {
            this.loading = true;
            this.$http
                .get(`http://zp.api.inlarks.com/api/zp/list/img?page=${this.page + 1}`)
                .then((res) => {
                    this.page += 1;
                    if (res.data.length < 15) this.ended = true;
                    res.data.forEach((row) => {
                        this.list.push(row);
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        showPreview(e, { index }) {
            this.preview_idx = parseInt(index);
            this.showPreviewStatus = true;
        },
        showDetailHandle(row) {
            this.temp = JSON.parse(JSON.stringify(row));
            this.temp.list = this.temp.list.map(x => {
                if (row.id == 2) {
                    //长图
                    return {
                        src: `http://zp.inlarks.com${x}?x-oss-process=image/crop,w_0,h_1000`,
                        href: "javascript:;"
                    }
                }
                else {
                    return {
                        src: `http://zp.inlarks.com${x}?x-oss-process=image/resize,w_400`,
                        href: "javascript:;"
                    }
                }
            });
            this.preview = row.list.map(item => 'http://zp.inlarks.com' + item + '?x-oss-process=image/resize,w_1200/watermark,text_ICBJTkxBUktTICA=,color_FFFFFF,size_60,t_10,g_center,fill_1');
            this.showDetail = true;


        },
        loadCompleted() {
            this.$nextTick(() => {
                this.$refs['waterfall'].waterfallOver()
            });
        },
        closeHandle() {
            this.showPreviewStatus = false;
            this.$emit("resetShare");
        },
        OnColumnChange() {
            this.page = 0;
            this.list = [];
            this.$showLoading();
            this.loadData();
        },
        windowScroll() {
            window.onscroll = () => {
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                //滚动条到底部的条件(距底部20px时触发加载)
                if (scrollTop + windowHeight >= scrollHeight - 100 && this.length > 0 && !this.ended && !this.loading) {
                    this.$showLoading();
                    this.loadData();
                }
            };
        },
    },
};
</script>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

.load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px 0;
}

.load-more div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 120px;
    background: #4a545d;
    border-radius: 25px;
    cursor: pointer;
    font-size: 12px;
    box-shadow: 0 2px 12px 0 rgb(255 255 255 / 10%);
}

.load-more div span {
    margin-bottom: 3px;
}

.load-more div:hover {
    background: #4f5861;
}

.icon-loading {
    font-size: 25px;
}

.preview-list {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.99);
    z-index: 1;
}

.close-btn {
    position: fixed;
    top: 16px;
    right: 26px;
    z-index: 2;
    width: 20px;
    cursor: pointer;
}

.preview-list>div {
    text-align: left;
    display: inline-block;
}

.preview-list>div img {
    width: 19%;
    transition: all 0.4s ease;
    cursor: pointer;
    margin: 1px;
}

.preview-list>div img:hover {
    transform: scale(1.1);
}

.content-list li {
    cursor: pointer;
}
</style>
