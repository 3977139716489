<template>
    <div class="container">
        <div class="video-container" v-if="detail">
            <video ref="videoPlayer" class="video-js" autoplay :poster="'http://zp.inlarks.com' + detail.cover + '?x-oss-process=image/resize,w_1000'" :src="'http://zp.inlarks.com' + detail.url" controls webkit-playsinline="true" crossorigin="anonymous" playsinline="true" preload="true" x-webkit-airplay="true" x5-video-player-type="h5-page"></video>

            <div class="title">
                <h1>{{ detail.title }}</h1>
            </div>
            <p>{{ detail.desc }}</p>
            <div class="icon-share">
                <div>
                    <div>
                        <div class="qr" ref="qrCodeUrl">
                            <img :src="'http://zp.api.inlarks.com/api/zp/qr?data=' + encodeURI(this.detailUrl)" />
                        </div>
                        <div class="copy" @click="handelCopy">
                            <input type="text" ref="detailUrl" readonly v-model="detailUrl" />
                            <i class="icon-copy" title="复制链接"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <i class="icon-close" @click="handleClose"></i>
    </div>
</template>

<script>
import videojs from "video.js";
import wx from "weixin-js-sdk";
export default {
    name: "VideoDetail",
    props: {
        detail: Object,
    },
    data() {
        return {
            isMobile: false,
            videoHeight: 0,
            detailUrl: "",
        };
    },
    mounted() {
        this.detailUrl = `http://${document.domain}${this.$route.path}?id=${this.detail.id}`;
        if (document.documentElement.clientWidth <= 600) {
            this.videoHeight = (9 * document.documentElement.clientWidth) / 16;
            this.isMobile = true;
            if (!this.IsWeixin()) return;
            var self = this;
            wx.ready(function() {
                self.Share();
            });
        } else {
            videojs(this.$refs.videoPlayer, {
                // autoplay: true,
                controls: true,
                liveui: false,
                height: 500,
                width: 890,
                controlBar: {
                    liveDisplay: true,
                    progressControl: true,
                    remainingTimeDisplay: true,
                    pictureInPictureToggle: false,
                },
                sources: [
                    {
                        src: "http://zp.inlarks.com" + this.detail.url,
                        type: "video/mp4",
                    },
                ],
            });
        }
    },
    methods: {
        Share() {
            var title = this.detail.title;
            var link = this.detailUrl;
            var imgurl = "http://zp.inlarks.com" + this.detail.cover + "?x-oss-process=image/resize,m_fill,h_400,w_400";
            var desc = this.detail.desc;
            wx.ready(function() {
                wx.onMenuShareTimeline({
                    title: title, // 分享标题
                    link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: imgurl,
                });
                wx.onMenuShareAppMessage({
                    title: title, // 分享标题
                    desc: desc, // 分享描述
                    link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: imgurl,
                });
            });
        },
        IsWeixin() {
            var ua = window.navigator.userAgent.toLowerCase();
            return ua.match(/MicroMessenger/i) == "micromessenger";
        },
        handleClose() {
            this.$emit("onClose");
        },

        handelCopy() {
            this.$refs.detailUrl.select();
            document.execCommand("copy");
        },
    },
};
</script>

<style scoped>
@import url("../../node_modules/video.js/dist/video-js.min.css");
.container {
    position: fixed;
    background: rgba(0, 0, 0, 0.9);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    .video-container {
        background: #333;
        height: 100%;
        width: 100%;
    }
    video {
        width: 100%;
        height: 210px;
    }

    .video-container h1 {
        color: #eee;
        margin: 10px 15px;
    }
    .video-container p {
        font-size: 16px;
        color: #eee;
        margin: 0 15px 20px 15px;
    }
    .video-js {
        width: 100%;
    }
    .icon-close {
        position: fixed;
        font-size: 50px;
        color: #fff;
        cursor: pointer;
        left: 50%;
        bottom: 40px;
        margin: 0 0 0 -25px;
    }
    .icon-share::before,
    .icon-share .copy {
        display: none;
    }
    .icon-share {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .icon-share .qr {
        margin: 0 20%;
        width: 60%;
    }
}
@media screen and (min-width: 601px) {
    .video-container {
        background: #1e1e1e;
        border: 1px #666 solid;
        position: relative;
    }
    .video-container .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .video-container h1 {
        color: #eee;
        margin: 10px 40px 10px 15px;
        font-size: 20px;
    }

    .video-container p {
        font-size: 14px;
        color: #eee;
        margin: 0 15px 20px 15px;
    }
    .icon-close {
        font-size: 40px;
        color: #fff;
        margin: 30px;
        cursor: pointer;
    }
    .icon-share {
        margin-right: 15px;
        position: absolute;
        cursor: pointer;
        font-size: 20px;
        padding: 6px;
        right: 0;
        bottom: 45px;
        color: rgb(71, 218, 255);
        z-index: 9999999;
    }
    .icon-share > div {
        position: relative;
    }
    .icon-share:hover > div > div {
        display: block;
    }
    .icon-share > div > div {
        position: absolute;
        width: 200px;
        height: 240px;
        background: #fff;
        right: -22px;
        top: -265px;
        display: none;
    }
    .icon-share > div > div > .qr {
        margin: 15px;
    }
    .icon-share > div > div .copy {
        padding: 5px 0;
        display: flex;
        width: 170px;
        margin: 0 15px;
        justify-content: space-between;
        align-items: center;
        border: 1px #ccc solid;
    }
    .icon-share > div .copy input {
        margin-left: 5px;
        outline: none;
        border: none;
        width: 140px;
    }
    .icon-share > div > div .copy i {
        margin-right: 5px;
        color: rgb(21, 132, 196);
    }
}
</style>
