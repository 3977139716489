<template>
    <div class="container home">
        <List @resetShare="resetShareHandle" v-if="columnId > 0"></List>
        <ImgList @resetShare="resetShareHandle" v-else></ImgList>
    </div>
</template>

<script>
import List from "@/components/List";
import ImgList from "@/components/ImgList";
import wx from "weixin-js-sdk";
import columns from "@/column.config.js";
export default {
    name: "Home",
    components: { List, ImgList },
    data() {
        return {
            columnId: columns[0].id,
        };
    },

    mounted() {
        if (this.$route.params.column) this.columnId = this.$route.params.column;
        if (this.IsWeixin()) this.InitWeixinShare();
    },
    watch: {
        $route(to) {
            this.columnId = to.params.column;
        },
    },
    methods: {
        IsWeixin() {
            var ua = window.navigator.userAgent.toLowerCase();
            return ua.match(/MicroMessenger/i) == "micromessenger";
        },
        Share() {
            var title = "灵雀互动作品展";
            var link = `http://zp.inlarks.com/${this.columnId}`;
            var imgurl = "http://zp.inlarks.com/logo.png";
            var desc = "灵雀互动作品展";
            wx.onMenuShareTimeline({
                title: title, // 分享标题
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgurl,
            });
            wx.onMenuShareAppMessage({
                title: title, // 分享标题
                desc: desc, // 分享描述
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgurl,
            });
        },
        resetShareHandle() {
            this.Share();
        },
        InitWeixinShare() {
            var self = this;
            wx.ready(function () {
                self.Share();
            });
            this.$http
                .post("https://open.mp.inlarks.com/api/mp/jssdk/signature?appid=wx01407fc5ce6c6ade", {
                    data: {
                        url: location.href.split("#")[0],
                    },
                })
                .then((res) => {
                    wx.config({
                        debug: false, // 开启调试模式,
                        appId: res.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
                        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                        signature: res.data.signature, // 必填，签名，见附录1
                        jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage", "updateAppMessageShareData", "updateTimelineShareData"],
                    });
                });
        },
    },
};
</script>
<style scoped>
.home {
    margin: 0 auto;
}
</style>
